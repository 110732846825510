import axios from 'axios';

import setupHostInterceptor from './hostInterceptor';
import setupRetryInterceptor from './retryInterceptor';
import setupDeviceInterceptor from './deviceInterceptor';
import setupVersionInterceptor from './versionInterceptor';
import setupIdentityInterceptor from './identityInterceptor';
import setupAccessTokenInterceptor from './accessTokenInterceptor';

const API_URL = `${process.env.API_HOST}/api`;

const instance = axios.create({
  baseURL: API_URL,
})

setupHostInterceptor(instance);
setupRetryInterceptor(instance);
setupDeviceInterceptor(instance);
setupVersionInterceptor(instance);
setupIdentityInterceptor(instance);
setupAccessTokenInterceptor(instance);

export default instance;

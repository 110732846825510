import { groupBy } from 'lodash';

import { byTeamId, byParticipantId, isInRegulationGameTime, compareTime } from '../util';
import { isGameCompleted } from './meta';

/**
 * Get all goals for a game with optional filters
 * @param {object} state
 * @param {object} [filters]
 * @param {number|string} [filters.teamId]
 * @param {number|string} [filters.participantId]
 * @param {boolean} [filter.isPowerplay]
 * @param {boolean} [filter.isShorthanded]
 * @param {boolean} [filter.isOvertime]
 * @param {boolean} [filter.isShootout]
 * @param {boolean} [filter.regulationOnly]
 */
export const getGoals = (state, sport, { teamId = null, participantId, isPowerplay, isShorthanded, isOvertime, isShootout, regulationOnly } = {}) => {
  if (!state) return [];
  let goals = [...state.goals];

  if (teamId) {
    goals = goals.filter(byTeamId(teamId));
  }
  if (participantId) {
    goals = goals.filter(byParticipantId(participantId));
  }
  if (isPowerplay != null) {
    goals = goals.filter(goal => goal.isPowerplay === isPowerplay);
  }
  if (isShorthanded != null) {
    goals = goals.filter(goal => goal.isShorthanded === isShorthanded);
  }

  if (regulationOnly) {
    if (sport === 'Hockey') {
      goals = goals.filter(goal => isInRegulationGameTime(goal.gameTime, isOvertime))
    }
  } else if (isOvertime) {
    if (sport !== 'Hockey') return []
    goals = goals.filter(goal => goal.gameTime.period == 'OT')
  } else if (isShootout) {
    if (sport !== 'Hockey') return []
    goals = goals.filter(goal => goal.gameTime.period == 'SO')
  }

  goals.sort((a, b) => compareTime(a.gameTime, b.gameTime, sport))

  return goals;
}

export const getScore = (state, sport, { teamId = null, maxScoreDiff = null, scoringMode } = {}) => {
  const manualScore = state && state.score && state.score[teamId];
  if (scoringMode === 'Score') return manualScore || 0;
  // forfeit score is optional, if not specified it falls back to the regulation score
  if (state.forfeit != null && manualScore != null) return manualScore;

  let regulationScore = getGoals(state, sport, { teamId, regulationOnly: true, isOvertime: true }).length;

  if (maxScoreDiff) {
    const allGoals = getGoals(state, sport, { regulationOnly: true, isOvertime: true })
    const goalsByTeam = groupBy(allGoals, 'teamId')
    const [ teamA, teamB ] = Object.keys(goalsByTeam);
    const teamAGoalCount = (goalsByTeam[teamA] && goalsByTeam[teamA].length) || 0;
    const teamBGoalCount = (goalsByTeam[teamB] && goalsByTeam[teamB].length) || 0;
    const goalDifferential = Math.abs(teamAGoalCount - teamBGoalCount);
    const winner = teamAGoalCount > teamBGoalCount ? teamA : teamB;
    const loser = teamAGoalCount < teamBGoalCount ? teamA : teamB;

    if (goalDifferential > maxScoreDiff && winner == teamId && loser != teamId) {
      const loserScore = (loser && goalsByTeam[loser].length) || 0;
      regulationScore = loserScore + maxScoreDiff;
    }
  }

  const shootoutGoals = getGoals(state, sport, { isShootout: true });

  if (isGameCompleted(state) && shootoutGoals.length > 0) {
    const goalsByTeam = groupBy(shootoutGoals, 'teamId')
    const shootoutWinner = Object.keys(goalsByTeam).reduce((teamA, teamB) => {
      const teamAGoalCount = (goalsByTeam[teamA] && goalsByTeam[teamA].length) || 0;
      const teamBGoalCount = (goalsByTeam[teamB] && goalsByTeam[teamB].length) || 0;

      if (teamAGoalCount === teamBGoalCount) return;

      return teamAGoalCount > teamBGoalCount ? teamA : teamB;
    })

    if (shootoutWinner == teamId) {
      return regulationScore + 1;
    }
  }

  return regulationScore;
}

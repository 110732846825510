import { combineReducers } from 'redux'

import {
  settings,
  startTime,
  endTime,
  status,
  notes,
  adminNotes,
  unreviewed,
  forfeit,
} from './meta';
import gameTime, { initialState as initialGameState } from './gameTime'
import officials from './officials';
import keepers from './keepers';
import lineups, { throwsInnings } from './lineups';
import goals from './goals';
import penalties from './penalties';
import goalies, { shots } from './goalies'
import flags from './flags';
import score from './score';
import purges from './purges';

// play nice with combineReducers
const ignore = initial => (state = initial, action) => state;
const scoresheet = sport => combineReducers({
  settings,
  gameTime: ignore(initialGameState[sport] || {}),
  startTime,
  endTime,
  status,
  score,
  officials: ignore([]),
  keepers,
  lineups,
  goals,
  penalties: ignore([]),
  goalies: ignore([]),
  purges,
  flags,
  shots,
  throwsInnings,
  forfeit,
  notes,
  adminNotes,
  unreviewed,
})

export default sport => (state = {}, action) => {
  // passing next state to gameTime to accurately calculate time on edits
  const nextState = scoresheet(sport)(state, action);
  return {
    ...nextState,
    gameTime: gameTime(nextState.gameTime, action, nextState, sport),
    goalies: goalies(state.goalies, action, nextState, sport),
    penalties: penalties(state.penalties, action, nextState),
    officials: officials(state.officials, action, nextState, state),
  }
}
